@import url(https://use.typekit.net/jtv4yqi.css);
body {
  margin: 0;
  background-color: #141a2a !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  overflow-x: hidden;
  width: 100vw;
  /* position: absolute; */
}

#root {
  overflow-x: hidden;
}

/* @font-face {
  font-family: thiccBoi;
  src: url(./fonts/THICCCBOI-ThicccAF.woff2);
} */

:root {
  --green: rgb(58, 172, 96);
}

.App {
  text-align: center;
}

::-webkit-scrollbar {
  width: 12px;
  /* border-radius: 8px; */
}

::-webkit-scrollbar-track {
  background: black;
}

::-webkit-scrollbar-thumb {
  background: rgb(58, 172, 96);
  border-radius: 4px;
}

.countdown-grid {
  text-align: center;
}

.countdown-item {
  padding: 25px;
}

.countdown-border-right {
  border-right: solid 1px rgba(255, 255, 255, 0.5);
}

.welcome-img {
  background-size: cover;
  background-position: center;
  /* height: 100vh; */
  background-repeat: no-repeat;
}

.hero-minter {
  position: absolute;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  left: 50%;
  margin-top: 30px;
  z-index: 100000000;
  bottom: 13%;

  /* z-index: 1000; */
}

.hero-presaleText {
  position: absolute;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  left: 50%;
  /* margin-top: 30px; */
  z-index: 100000000;
  /* bottom: 13%; */
  /* z-index: 1000; */
}

.black {
  color: black;
}

.inc-button {
  transition: all 0.2s ease-in-out !important;
  /* color: white !important; */
  /* background:  white !important; */
  cursor: pointer;
}

.connect-button:disabled {
  background-color: rgba(0, 0, 0, 0.7) !important;
  color: #646464 !important;
}

.wrapper {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}

.message {
  position: absolute;
  top: 20%;
  /* left: 50%; */
  left: 50px;
  min-width: 150px;
  font-weight: bold;
  max-width: 80%;
}

.container {
  margin: 10% 0;
  overflow-x: hidden;
  overflow-y: visible !important;
}

.parentcontainer {
  z-index: 1 !important;
  position: relative;
}

.secondgalleryparent {
  margin-top: 175px;
}

.customcontainer {
  overflow-x: hidden;
  overflow-y: hidden;
  /* margin: 2% 0 !important; */
  /* background-color: white; */
  z-index: 2 !important;
}

.gallery-container {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  /* background-color: #646464; */
}

.fade-container {
  /* background-color: orange; */
  position: absolute;
  height: 100%;
  width: 100%;
  max-width: none !important;
  z-index: 1000;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  /* padding-bottom: 100px !important; */
}

.translate-left {
  -webkit-transform: translateX(-30vw) !important;
          transform: translateX(-30vw) !important;
}

.translate-right {
  -webkit-transform: translateX(30vw) !important;
          transform: translateX(30vw) !important;
}

@media (min-width: 450px) {
  .connect-button:hover {
    letter-spacing: 0.25em !important;
    color: rgb(58, 172, 96) !important;
    /* transform: translateY(-2px) !important; */
  }
}

.connect-button {
  font-size: 22px !important;
  text-transform: uppercase;
  letter-spacing: 2px !important;
  font-family: azo-sans-uber, sans-serif !important;
  font-weight: 400 !important;
  font-style: normal;
  transition: all 0.2s ease-in-out !important;
  color: white !important;
  background: black !important;
  min-width: 200px !important;
  cursor: pointer;
  padding: 16px 16px !important;
  border-radius: 8px !important;
  z-index: 1000;
  box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
  -webkit-animation: float 3.5s ease-in-out infinite;
          animation: float 3.5s ease-in-out infinite;
}

.incr-decr {
  /* color: white; */
  background: rgba(0, 0, 0, 0.8);
  border-radius: 5px;
  display: flex;
  margin-top: 20px;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  width: 200px;
  justify-content: space-between;
  cursor: pointer;
  background-color: rgb(58, 172, 96, 0.9);
  color: white;
  box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
  /* animation: float 4s ease-in-out infinite; */
  /* border: solid 1px rgba(255, 255, 255, 0.3);
  border: var(--green); */
  /* max-width: 30%; */
}

.connect-button span {
  justify-content: center;
}

.MuiTypography-h6 {
  letter-spacing: 1px !important;
  font-family: azo-sans-uber, sans-serif !important;
  font-weight: 400;
  font-size: 18px !important;
  text-transform: uppercase;
}

.makeStyles-root-1 .MuiDialogContent-root .MuiListItem-root .MuiButton-root {
  font-family: bicyclette, sans-serif !important;
  font-style: normal;
  font-size: 16px !important;
  font-weight: 100 !important;
  letter-spacing: 1px !important;
}

.countdown-value {
  font-family: bicyclette, sans-serif;
  font-style: normal;
  font-size: 20px;
  font-weight: 100;
  letter-spacing: 2px;
  font-size: 35px;
  margin: 0px !important;
  font-weight: 700;
  color: white;
}

.countdown-text {
  font-family: bicyclette, sans-serif;
  font-style: normal;
  font-size: 20px;
  font-weight: 100;
  letter-spacing: 2px;
  font-size: 16px;
  margin: 0px !important;
  font-weight: 700;
  color: #cdcdcd;
}

.supply-container {
  display: flex;
  width: 100%;
  justify-content: center;
}

.supply-left {
  font-family: bicyclette, sans-serif;
  font-style: normal;
  font-size: 20px;
  font-weight: 100;
  letter-spacing: 2px;
  font-size: 16px;
  font-weight: 700;
  /* color: #cdcdcd; */
  color: rgb(58, 172, 96);
  /* color: #00ff00; */
  /* text-transform: uppercase;
  font-weight: 400; */
  /* letter-spacing: 5px; */
  /* background-color: rgba(0, 0, 0, 0.5); */
  /* color: white;
  color: var(--green); */
  text-align: center;
  margin: 7.5px 5px 0px 5px !important;
}

.supply-presaleText {
  font-family: bicyclette, sans-serif;
  font-style: normal;
  font-size: 20px;
  font-weight: 100;
  letter-spacing: 2px;
  /* width: 50% !important; */
  text-transform: uppercase !important;
  font-size: 16px;
  /* margin: 30px 0px !important; */
  /* letter-spacing: 3px; */
  font-weight: 700;
  /* color: #00ff00; */
  color: rgb(58, 172, 96);
  background-color: none !important;
  margin: 7.5px 5px 0px 5px !important;
}

.presaleText {
  /* width: 100% !important; */
  text-transform: uppercase !important;
  font-size: 15px;
  margin: 30px 0px !important;
  letter-spacing: 3px;
  /* font-weight: 700; */
  color: rgb(58, 172, 96);
  background-color: rgba(0, 0, 0, 0.8) !important;

  /* text-transform: uppercase;
  font-weight: 400; */
  /* letter-spacing: 5px; */
  text-align: center;

  /* background-color: rgba(0, 0, 0, 0.5); */
  /* color: white;
  color: var(--green); */
  padding: 1em !important;
  /* text-align: center; */
  /* border: solid 1px rgba(255,255,255,0.3) !important; */

  /* margin-bottom: 20px; */
  border-radius: 4px !important;
  /* animation: float 4s ease-in-out infinite; */
  /* margin-bottom: 110px !important; */
}

/* .navbar {
  position: fixed;
  z-index: 10000;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
} */

@media (max-width: 750px) {
  /* .connect-button {
    font-size: 25px !important;
  } */

  /* .welcome-img {
    display: none;
  } */

  .countdown-container {
    margin-top: 70px !important;
    max-width: 95%;
    margin: auto;
    /* position: absolute; */
  }

  .table-row,
  .table-body,
  .table-cell {
    display: block !important;
    text-align: left !important;
  }

  .table-row {
    border-top: 1px solid !important;
  }

  .table-cell {
    border: none !important;
  }

  .table-cell-desc {
    padding-top: 0 !important;
  }

  /* .table-cell-desc p {
    margin-top: 0px !important;
  } */
}

[data-aos="gallery-animation"] {
  opacity: 0;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
}
[data-aos="gallery-animation"].aos-animate {
  opacity: 1;
}

@media screen and (min-width: 768px) {
  [data-aos="gallery-animation"] {
    -webkit-transform: translateX(100px);
            transform: translateX(100px);
  }
  [data-aos="gallery-animation"].aos-animate {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }

  /* p {
    font-size: 25px;
  } */
}

.gallery-img {
  height: 280px;
  margin: 20px;
  /* padding: 40px; */
  border-radius: 8px;
  /* border-color: white !important;
  border:solid; */
  /* padding: 40px;
  background-color: #1d263b; */
}

@media (max-width: 450px) {
  /* h6 {
    font-size: 20px !important;
  }

  .MuiAlert-message h6 {
    font-size: 12px !important;
  } */

  .minter-navbar {
    display: none;
  }

  .gallery-translate {
    -webkit-transform: translateX(-150%) !important;
            transform: translateX(-150%) !important;
  }

  .countdown-value {
    font-size: 20px;
  }

  .countdown-text {
    font-size: 10px;
  }

  .contract-btn {
    font-size: 12px;
  }

  .countdown-item {
    padding: 15px !important;
  }
}

.float {
  -webkit-animation: float 6s ease-in-out infinite;
          animation: float 6s ease-in-out infinite;
}

@-webkit-keyframes float {
  0% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    -webkit-transform: translatey(0px);
            transform: translatey(0px);
  }
  50% {
    box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
    -webkit-transform: translatey(-15px);
            transform: translatey(-15px);
  }
  100% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    -webkit-transform: translatey(0px);
            transform: translatey(0px);
  }
}

@keyframes float {
  0% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    -webkit-transform: translatey(0px);
            transform: translatey(0px);
  }
  50% {
    box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
    -webkit-transform: translatey(-15px);
            transform: translatey(-15px);
  }
  100% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    -webkit-transform: translatey(0px);
            transform: translatey(0px);
  }
}

.countdown-container {
  /* position: fixed;
  left: 50%;
  transform: translateX(-50%); */
  text-align: center;
  margin-top: 30px;
  justify-content: center;
  -webkit-animation: float 4s ease-in-out infinite;
          animation: float 4s ease-in-out infinite;
}

.table-cell {
  color: white !important;
}

.title-container {
  padding-bottom: 40px;
}

.welcome-img-container {
  -webkit-mask: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.65) 0%,
    rgba(255, 255, 255, 0) 0%,
    rgba(2, 2, 2, 1) 30%
  );
}

.mint-button {
  font-size: 25px !important;
}

.alert {
  font-size: 25px;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 3px;
  font-family: azo-sans-uber, sans-serif;
  font-style: normal;
  margin: 10px;
  position: fixed !important;
  z-index: 999999999999999 !important;
  bottom: 10% !important;
  width: 90% !important;
  left: 50% !important;
  -webkit-transform: translateX(-50%) !important;
          transform: translateX(-50%) !important;
  max-width: 500px !important;
}

.alert .MuiAlert-icon {
  align-items: center;
}

.alert-text {
  font-size: 25px;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 3px;
  font-family: azo-sans-uber, sans-serif;
  font-style: normal;
  margin: 10px;
}

.banner {
  font-size: 30px !important;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 3px;
  font-family: azo-sans-uber, sans-serif;
  font-style: normal;
  margin: 10px;
  color: white !important;

  /* color: var(--green); */
}

.mintcount-text {
  font-size: 25px !important;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 3px;
  font-family: azo-sans-uber, sans-serif;
  font-style: normal;
  margin: 15px;
  color: white !important;
}

.circular-progress {
  color: white;
}

@media screen and (min-width: 1400px) {
  .banner {
    font-size: 30px !important;
  }

  .countdown-value {
    font-size: 35px !important;
  }

  .connect-button {
    font-size: 22px !important;
  }

  .countdown-text,
  .supply-presaleText,
  .supply-left,
  .presaleText {
    font-size: 16px !important;
  }

  .mintcount-text,
  .mint-button {
    font-size: 25px !important;
  }
}

@media screen and (max-width: 1399px) {
  .banner {
    font-size: clamp(1.5rem, 0.3173rem + 1.8029vw, 2rem) !important;
  }

  .countdown-value {
    font-size: clamp(1.9rem, 0.092rem + 2.3978vw, 2.33rem) !important;
  }

  /* .connect-button {
    font-size: clamp(1.1rem, 0.9123rem + 0.5288vw, 1.375rem) !important;
  } */

  .countdown-text,
  .supply-presaleText,
  .supply-left,
  .presaleText {
    font-size: clamp(0.7rem, 0.8317rem + 0.2043vw, 1rem) !important;
  }

  /* .mintcount-text,  */
  .mint-button {
    font-size: clamp(1.45rem, 1.1225rem + 0.5vw, 1.56rem) !important;
  }
}

@media screen and (max-height: 700px) {
  .countdown-grid {
    display: none !important;
  }

  /* .countdown-subcontainer {
    border: none !important;
  } */

  /* .incr-decr {
    margin-bottom: 10px;
  } */
  /* 
  .supply-left {
    display: none !important;
  } */
}

@media screen and (max-width: 600px) {
  .countdown-grid {
    display: none !important;
  }

  .gallery-img {
    height: 130px !important;
  }

  .secondgalleryparent {
    margin-top: 115px !important;
  }

  /* .countdown-subcontainer {
    border: none !important;
  } */
}

